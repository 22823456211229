import Alpine from "alpinejs";

Alpine.data("vimeoVideo", () => {
    return {
        resize() {
            var $frame = this.$el;
            var $parent = $frame.closest(".o-section__masked");

            var height = $parent.clientHeight,
                width = $parent.clientWidth,
                heightFactor = height / 9,
                widthFactor = width / 16;

            var stretchFactor;
            if (heightFactor < widthFactor) {
                stretchFactor = widthFactor / heightFactor;
            } else {
                stretchFactor = heightFactor / widthFactor;
            }

            let desiredWidth = width * stretchFactor;
            let desiredHeight = height * stretchFactor;

            $frame.style.height = desiredHeight + "px";
            $frame.style.width = desiredWidth + "px";
        },
    };
});
