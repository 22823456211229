import Alpine from "alpinejs";

Alpine.store("blog", {
    navigate(page, tag) {
        this.page = page;
        this.tag = tag;
    },
    page: 1,
    tag: null,
});

Alpine.data("blog", ($el) => {
    let parser = new DOMParser();

    return {
        lastUpdate: Date.now(),
        hasMore: false,
        basePage: '',
        async load(reload) {
            let culture = document.querySelector("meta[name=language]").content;
            let href = `/umbraco/surface/articles/index?page=${this.$store.blog.page}&tag=${this.$store.blog.tag}&culture=${culture}`;
            let response = await fetch(href);
            let html = await response.text();

            let doc = parser.parseFromString(html, "text/html");

            if (reload) {
                this.$refs.stream.innerHTML = "";
            }

            let items = doc.querySelectorAll(".of-news-article-grid__item");
            for (let i = 0; i < items.length; i++) {
                this.$refs.stream.append(items[i]);
            }

            // has more?
            this.hasMore =
                doc.querySelector("div.elements").dataset["hasMore"] === "True";

            Alpine.nextTick(() => {
                document.dispatchEvent(new CustomEvent("blog:articles-changed"));
            });
        },
        async reload() {
            this.load(true);
        },
        async loadMore() {
            this.$store.blog.page++;
            await this.load();
        },
        async navigateToTag(tag) {
            if (this.$store.blog.tag === tag) tag = "";

            Turbo.visit(this.$store.blog.basePage + "?tag=" + tag);
        },
        async navigateInitial(blogBasePage, page, tag){
            this.$store.blog.basePage = blogBasePage;
            await this.navigate(page, tag)
        },
        async navigate(page, tag) {
            // deselect the tag when it's the same
            let currentTag = this.$store.blog.tag;
            if (tag === currentTag) {
                tag = "";
            }

            this.$store.blog.page = page;
            this.$store.blog.tag = tag;

            await this.reload();
        },
    };
});
