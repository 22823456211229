import "./css/index.scss";
import "@hotwired/turbo"
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import "./swiper.js";
import "./topmenu.js";
import "./block.js";
import "./vimeo-integration.js";
import './alpine-data.js'

Alpine.plugin(intersect);
window.Alpine = Alpine;

if ("serviceWorker" in navigator) {
    let registerServiceWorker = async () => {
        // reload once when the new Service Worker starts activating
        var refreshing;
        navigator.serviceWorker.addEventListener(
            "controllerchange",
            function () {
                if (refreshing) return;
                refreshing = true;
                window.location.reload();
            }
        );

        let serviceWorkerPath = "/dist/service-worker.js";
        let registration = await navigator.serviceWorker.register(
            serviceWorkerPath,
            {
                scope: "/",
            }
        );
        // Track updates to the Service Worker.
        if (navigator.serviceWorker.controller) {
            registration.update();
        }

        return registration;
    };

    window.addEventListener("load", async () => {
        if (typeof registerServiceWorker === "function") {
            await registerServiceWorker();
        }
    });
}

window.Alpine.start();

import("./lazy-loading.js").then(() => {
    // we use Turbo Drive for navigation, and turbo:render is our navigation event
    document.addEventListener("turbo:load", async (event) => {
        window.lazyLoadInstance.update();
    });
    document.addEventListener("turbo:frame-load", async (event) => {
        window.lazyLoadInstance.update();
    });

    // reset the SubNav to be closed when navigating
    document.addEventListener("turbo:before-visit", (event) => {
        Alpine.store("ui").closeSubNav();
        Alpine.store("ui").closeMobileMenu();
        Alpine.store("ui").closeSiteMenu();
    });
});

// cookie consent
import("./cookie-consent.js");

import("./buttons.js").then((ex) => {
    ex.default();

    document.addEventListener("turbo:load", async (event) => {
        ex.default();
    });
    document.addEventListener("turbo:frame-load", async (event) => {
        ex.default();
    });
});