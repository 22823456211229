import Alpine from "alpinejs";

Alpine.store("ui", {
    toggleSubNav() {
        this.subNavOpen = !this.subNavOpen;

        if (!window.zE) return;

        if (this.subNavOpen) {
            zE("messenger:set", "zIndex", 1);
        } else {
            zE("messenger:set", "zIndex", 10);
        }
    },

    openSiteMenu(idx) {
        this.siteMenuOpen = true;
        this.siteMenuIndex = idx;
    },

    closeSiteMenu() {
        this.siteMenuOpen = false;
        this.siteMenuIndex = -1;
    },

    openSiteSubMenu(idx) {
        this.siteSubMenuIndex = idx;
    },

    openMobileMenu(idx) {
        this.mobileMenuIndex = idx;
        this.mobileSubMenuIndex = -1;
        this.mobileSubSubMenuIndex = -1;
        this.mobileMenuOpen = true;
    },
    closeMobileMenu() {
        console.log("closeMobileMenu");
        this.mobileMenuIndex = -1;
        this.mobileSubMenuIndex = -1;
        this.mobileSubSubMenuIndex = -1;
        this.mobileMenuOpen = false;
    },
    openMobileSubMenu(idx) {
        console.log("openMobileSubMenu", idx);
        this.mobileSubMenuIndex = idx;
        this.mobileSubSubMenuIndex = -1;
    },
    closeMobileSubMenu() {
        console.log("closeMobileSubMenu");
        this.mobileSubMenuIndex = -1;
        this.mobileSubSubMenuIndex = -1;
    },
    openMobileSubSubMenu(idx) {
        console.log("openMobileSubSubMenu", idx);
        this.mobileSubSubMenuIndex = idx;
    },
    closeMobileSubSubMenu() {
        console.log("closeMobileSubSubMenu");
        this.mobileSubSubMenuIndex = -1;
    },
    closeSubNav() {
        this.subNavOpen = false;
    },
    toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    mobileMenuOpen: false,
    mobileMenuIndex: -1,
    mobileSubMenuIndex: -1,
    mobileSubSubMenuIndex: -1,
    siteMenuOpen: false,
    siteMenuIndex: -1,
    siteSubMenuIndex: -1,
});

Alpine.data("topNav", () => {
    return {
        toggleMobileSiteMenu() {
            this.$store.ui.toggleMobileMenu();
        },
        onMouseOut(element) {},
        onMouseEnter(element) {
            let idx = parseInt(element.dataset["idx"]);

            if (element.dataset.hasSubmenu) {
                this.$store.ui.openSiteMenu(idx);
            } else {
                this.$store.ui.closeSiteMenu();
            }
        },

        init() {
            let openClass = "c-top-nav__item--open";
            Alpine.effect(() => {
                let idx = this.$store.ui["siteMenuIndex"];
                let topNavItems = this.$el.querySelectorAll(".c-top-nav__item");
                topNavItems.forEach((el) => {
                    if (parseInt(el.dataset["idx"]) == idx) {
                        el.classList.add(openClass);
                        let left = el.offsetLeft;

                        document.documentElement.style.setProperty(
                            "--site-menu-translate-x",
                            `${left}px`
                        );
                        document.documentElement.style.setProperty(
                            "--site-menu-left",
                            `0px`
                        );
                    } else {
                        el.classList.remove(openClass);
                    }
                });
            });
        },
    };
});

Alpine.data("siteSubMenu", () => {
    return {
        init() {
            this.idx = this.$root.dataset["idx"];
            Alpine.effect(() => {
                let siteSubMenuIndex = this.$store.ui["siteSubMenuIndex"];
                this.isOpen = siteSubMenuIndex == this.idx;
            });
        },
        idx: 0,
        isOpen: false,
    };
});
Alpine.data("siteMenuSection", () => {
    return {
        isOpen: false,
        onMouseOut($event) {},
        onMouseEnter($event) {
            // for desktop
            let index = $event.target.dataset["idx"];
            this.$store.ui.openSiteSubMenu(index);
        },
        openSiteMenu() {
            // for mobile
            this.$store.ui.openSiteMenu(this.$el.dataset["idx"]);
        },
        init() {
            this.subIndex = this.$el.dataset["sub-idx"];
            Alpine.effect(() => {
                let activeIdx = Alpine.store("ui").siteMenuIndex;
                let activeSubMenuIndex = Alpine.store("ui").siteSubMenuIndex;

                // find the right sub menu items and add the open class
                let subItems = this.$el.querySelectorAll(".c-site-menu__item");
                subItems.forEach((x) => {
                    let index = parseInt(x.dataset["idx"]);
                    if (index == activeSubMenuIndex) {
                        x.classList.add("open");
                    } else {
                        x.classList.remove("open");
                    }
                });

                this.isOpen = activeIdx == this.$root.dataset["idx"];
            });

            this.$watch("isOpen", (isOpen) => {
                if (!isOpen) return;

                let width = this.$root.clientWidth;
                let height = this.$root.clientHeight;

                document.documentElement.style.setProperty(
                    "--site-menu-width",
                    width + "px"
                );
                document.documentElement.style.setProperty(
                    "--site-menu-height",
                    height + "px"
                );
            });
        },
    };
});
Alpine.data("sitemenu", () => {
    return {
        isOpen: false,
        openItemIndex: -1,
        width: 100,
        height: 100,
        init() {
            // make it a normal array
            this.sections = Array.prototype.slice.call(
                this.$root.querySelectorAll(".c-site-menu__section-wrapper")
            );

            Alpine.effect(() => {
                let siteMenuOpen = Alpine.store("ui").siteMenuOpen;
                let siteMenuIdx = Alpine.store("ui").siteMenuIndex;

                console.log(siteMenuIdx)
                this.isOpen = siteMenuOpen;
                this.openItemIndex = siteMenuIdx;
            });

            this.topSections = document.querySelectorAll(
                ".c-top-nav .c-top-nav__item[data-idx]"
            );

            this.$watch("openItemIndex", (itemIndex, oldIndex) => {
                if (itemIndex != oldIndex) {
                    this.$store.ui.openSiteSubMenu(-1);
                }
            });
        },
        onMouseOut(element) {
            this.$store.ui.closeSiteMenu();
        },
        onMouseEnter(element) {},
    };
});

Alpine.data("mobileMenu", () => {
    return {
        isOpen: true,
        currentMenuIndex: -1,
        init() {
            Alpine.effect(() => {
                this.isOpen = this.$store.ui["mobileMenuOpen"];
                this.currentMenuIndex = this.$store.ui["mobileMenuIndex"];
            });
        },
        menuItemClicked($event) {
            let idx = $event.currentTarget.dataset["idx"];
            this.$store.ui.openMobileSubMenu(idx);
        },
    };
});

Alpine.data("mobileSubMenu", () => {
    return {
        isOpen: false,
        index: -1,
        init() {
            Alpine.effect(() => {
                let currentMenuIndex = this.$store.ui["mobileSubMenuIndex"];
                let rootOpen = this.$store.ui["mobileMenuOpen"];

                this.isOpen = this.index === currentMenuIndex && rootOpen;
            });
            this.index = this.$root.parentNode.dataset["idx"];
        },

        menuSubItemClicked($event) {
            let target = $event.currentTarget.closest(".c-site-menu__item");
            let idx = target.dataset["idx"];
            this.$store.ui.openMobileSubSubMenu(idx);
        },
        close() {
            this.$store.ui.closeMobileSubMenu();
        },
    };
});

Alpine.data("mobileSubSubMenu", () => {
    return {
        isOpen: false,
        index: -1,
        rootIndex: -1,
        init() {
            Alpine.effect(() => {
                let currentMenuIndex = this.$store.ui["mobileSubMenuIndex"];
                let currentSubMenuIndex =
                    this.$store.ui["mobileSubSubMenuIndex"];
                let rootOpen = this.$store.ui["mobileMenuOpen"];

                this.isOpen =
                    this.index === currentSubMenuIndex &&
                    this.rootIndex === currentMenuIndex &&
                    rootOpen;
            });
            this.index = this.$root.dataset["idx"];
            this.rootIndex = this.$root.dataset["rootIdx"];
        },

        close() {
            this.$store.ui.closeMobileSubSubMenu();
        },
    };
});
