import Alpine from "alpinejs";
import anime from "animejs";

Alpine.data("block", () => {
    return {
        inView: false,
    };
});

Alpine.data("heroBlock", () => {
    return {
        inView: false,
        animate($element) {
            let targets = $element.querySelectorAll("[x-show]");
            this.inView = true;

            requestAnimationFrame(() => {
                anime({
                    targets: targets,
                    opacity: [0.0, 1.0],
                    delay: anime.stagger(200), // increase delay by 100ms for each elements.
                });
            });
        },
    };
});
